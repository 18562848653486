<template>
  <list-container-widget
    id="organizations-by-type-list"
    :title="widgetName"
    :app-id="app.id"
    stretch
    class="d-flex"
  >
    <div v-if="isLoading" class="h-100 w-100 align-items-center justify-content-center d-flex">
      <b-spinner class="my-5" variant="primary" />
    </div>
    <div v-else>
      <b-table
        v-if="types.length > 0"
        class="organiaztions-table"
        thead-class="d-none"
        tbody-tr-class="organiaztions-table__row"
        :items="types"
        responsive
        :fields="fields"
        primary-key="key"
        show-empty
        hover
        empty-text="No matching records found"
        :small="small"
      >
        <template #cell(name)="{ item: type }">
          {{ translate(type.namePlural) }}
          <b-badge pill class="ml-50">
            {{ getTotal(type) }}
          </b-badge>
        </template>
        <template #cell(actions)="row">
          <div class="organization-actions d-flex justify-content-end align-items-center flex-wrap">
            <b-button
              variant="link"
              size="md"
              class="mr-sm-2 organization-table_button font-weight-normal pr-0 organizations-button"
              @click="row.toggleDetails"
            >
              <feather-icon
                :icon="row.detailsShowing ? 'ChevronUpIcon' : 'ChevronDownIcon'"
                size="16"
                class="widget-link"
              />
              {{ $t(`action.${row.detailsShowing ? 'hide' : 'show'}-item`, []) }}
            </b-button>
            <b-link
              :to="getViewMoreLocation(row.item)"
              class="text-primary widget-link view-more-location organizations-view-more"
            >
              {{ $t('link.viewMore') }}
            </b-link>
          </div>
        </template>
        <template #row-details="row">
          <widget :enable-card-size="enableCardSize">
            <organizations-paginated-table
              :type-key="row.item.key"
              :listing-type="listingType"
              :load-first-page="false"
              :per-page="perPage"
            />
          </widget>
        </template>
      </b-table>
      <!-- Container without data: Placeholder -->
      <b-row v-else class="horizontal-placeholder">
        <b-col cols="12">
          <img :src="placeholder">
        </b-col>
        <b-col cols="12">
          <p class="text-primary">
            {{ $t('available.message', { itemName: $t('organizations.organizations-by-type.title') }) }}
          </p>
        </b-col>
      </b-row>
    </div>
  </list-container-widget>
</template>

<script>
import OrganizationsPlaceholder from '@/assets/images/placeholders/light/organizations.svg';
import { translateTranslationTable } from '@/@core/libs/i18n/utils';
import ListContainerWidget from '@core/widgets/ListContainerWidget.vue';
import WidgetLayoutMixin from '@core/mixins/widgets/WidgetLayoutMixin';
import CardWidgetMixin from '@core/mixins/widgets/CardWidgetMixin';
import Widget from '@core/widgets/Widget.vue';
import { OrganizationListingType } from '@copernicsw/community-common';
import OrganizationsPaginatedTable from '../components/OrganizationsPaginatedTable.vue';

export default {
  name: 'OrganizationsByTypeTableWidget',
  components: {
    Widget,
    OrganizationsPaginatedTable,
    ListContainerWidget,
  },
  mixins: [WidgetLayoutMixin, CardWidgetMixin],
  props: {
    perPage: {
      type: Number,
      default: 8,
    },
    listingType: {
      type: Number,
      default: OrganizationListingType.forCommunity,
    },
  },
  data() {
    return {
      isLoading: true,
      opennedType: null,
      small: false,
    };
  },
  computed: {
    placeholder() {
      return OrganizationsPlaceholder;
    },
    types() {
      const organizationsByType = this.$store.getters.organizations.byType;
      console.log('organizationsByType', organizationsByType);
      if(organizationsByType.userNotInsideCommunity){
        return [];
      }
      if (organizationsByType) {
        return organizationsByType.map((type) => ({
          ...type.type,
          _showDetails: this.opennedType === type.key,
        }));
      }
      return [];
    },
    locale() {
      return this.$store.state.locale.currentLocale;
    },
    fields() {
      return [
        {
          key: 'name',
          label: this.$t('name.title'),
        },
        {
          key: 'actions',
          label: '',
        },
      ];
    },
  },
  async created() {
    this.isLoading = true;
    await this.fetchData();
    this.isLoading = false;
    window.addEventListener('resize', this.handleResize);
    this.handleResize();
  },
  destroyed() {
    window.removeEventListener('resize', this.handleResize);
  },
  methods: {
    async fetchData() {
      await this.$store.dispatch('getItems', {
        itemType: 'organizationsByType',
        customName: 'organizations',
        storedKey: 'byType',
        requestConfig: {
          listingType: this.listingType,
          page: 1,
          perPage: this.perPage,
        },
      });
    },
    translate(string) {
      return translateTranslationTable(this.currentLocale, string);
    },
    translateTypeName(type) {
      return type.name
        ? translateTranslationTable(this.$store.state.locale.currentLocale, type.name)
        : this.$t('organizations.title');
    },
    getViewMoreLocation(type) {
      return {
        name: 'organizations-type',
        params: { type: type?.name?.en.toLowerCase(), typeKey: type.key, communityId: this.$route.params.communityId },
      };
    },
    handleResize() {
      this.small = window.innerWidth < 400;
    },
    getTotal(type) {
      if (this.$store.getters.organizations.byType != null && type.key != null) {
        const data = this.$store.getters.organizations.byType;
        return data.find((element) => element.type.key === type.key).models.total;
      }
      return 0;
    },
  },
};
</script>
<style lang="scss" scoped>
@import '~@core/scss/base/bootstrap-include';
.organizations-view-more {
  padding-top: 0.2rem;
}
@include media-breakpoint-down(md) {
  .organizations-button {
    order: 2;
  }
}
</style>
